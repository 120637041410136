import React, { useState } from 'react'

import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import './register.css'

import FormSuccess from '../components/form-success'

const Register = (props) => {

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    employee_count: "",
    registration_page: "smart-defend.de",
    email_address: "",
  });

  const [submit, setSubmit] = useState(false);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    registerUser(formData);
    setSubmit(!submit)
  };
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const registerUser = (data) => {
    return fetch('https://hc.nsurio.com/api/v1/register/', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'b7061546-75e8-444b-a2c4-f19655d07eb8',
            'Content-Type': 'application/json'
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            console.log(response);
            return response;
           
          } else {
           console.log('error');
          }
    }).catch(err => err);
  }

  return (
    <div className="hero-container">

      <Helmet>
        <title>Register - Smart Defend</title>
        <meta property="og:title" content="Register - Smart Defend" />
      </Helmet>

      <section className="register-hero">
        
        <div className="home-menu">
          <div id="register-menu" className="home-mobile-navigation">
              <img
                alt="Smart Defend Logo"
                src="/pastedimage-no9b-1500h.png"
                className="home-logo4"
              />
            <div className="home-links">
              <span className="Link">Solutions</span>
              <span className="Link">How it works</span>
              <span className="Link">Prices</span>
            </div>
            <div id="close-mobile-menu" className="home-close-mobile-menu">
              <svg viewBox="0 0 804.5714285714286 1024" className="home-icon">
                <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
              </svg>
            </div>
          </div>

          <div className="home-desktop-navigation">
            <nav className="home-centered">
              <div className="home-left">
                <Link to="/">
                  <img
                    alt="Smart Defend Logo"
                    src="/smart-defend-high-resolution-logo-black-transparent-200h.png"
                    className="home-logo4"
                  />
                </Link>
                <div className="home-links1">
                  <span className="Link">Solutions</span>
                  <span className="Link">How it works</span>
                  <span className="Link">Prices</span>
                </div>
              </div>
              <div className="home-right">
                <span className="home-sign-in Link">
                  <Link to="/register">Sign in</Link>
                </span>
                <div className="home-get-started">
                  <span className="home-text06">
                  <Link to="/register">Get started</Link>
                  </span>
                </div>
                <div id="open-mobile-menu" className="home-burger-menu">
                  <Link to="/">
                    <img
                      alt="Open Mobile Menu"
                      src="/pastedimage-yxbd.svg"
                      className="home-mobile-menu-button"
                    />
                  </Link>
                </div>
              </div>
            </nav>
          </div>

        </div> 

      </section>



      <div className="register-banner">
        <span className="register-text">
          <span className="register-text01">CYBER RESILLLIENCE ASSESSMENT</span>
        </span>
        <h1 className="register-text02">Understand and minimize your cybersecurity risk</h1>
        <span className="register-text03">
          <span>
            <span>
            Complete our free cyber resiliency assessment for a quick but comprehensive health check that measures your organisation’s ability to detect, respond to and recover from cyber threats.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
          <span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
        </span>
         
        <div className="register-container1">
        {!submit ? (
          <form onSubmit={handleSubmit}>
            <input 
              type="hidden" 
              name="registration_page"
            /> 
          <input
            type="text"
            name="first_name"
            label="First Name"
            placeholder="First Name"
            className="register-textinput input"
            value={formData.first_name}
            onChange={handleChange}
            required={true}
          />
          <input
            type="text"
            name="last_name"
            label="Last Name"
            placeholder="Last Name"
            className="register-textinput input"
            value={formData.last_name}
            onChange={handleChange}
            required={true}
          />
          <input
            type="text"
            name="email_address"
            label="Email Address"
            placeholder="Email Address"
            className="register-textinput input"
            value={formData.email_address}
            onChange={handleChange}
            required={true}
          />
          <input
            type="text"
            name="company_name"
            placeholder="Company Name"
            className="register-textinput input"
            value={formData.company_name}
            onChange={handleChange}
            required={true}
          />
          <select
            type="text"
            name="employee_count"
            placeholder="Number of Employees"
            className="register-select input"
            value={formData.employee_count}
            onChange={handleChange}
            required={true}
          >
            <option>Number of Employees</option>
            <option value="1-0">1-10</option>
            <option value="10-50">10-50</option>
            <option value="50-200">5-200</option>
            <option value="200">Mehr als 200</option>

          </select>
          <button className="register-get-started1 button">Sign Up Now!</button>
          </form>
          ) : (
            <FormSuccess />
          )}
        </div>
      </div>

      <footer className="home-footer">
        <div className="home-top">
          <div className="home-right1">
            <div className="home-category">
              <span className="home-text39">Company</span>
              <div className="home-links2">
                <span className="home-text40">About</span>
                <span className="home-text41">Team</span>
                <span className="home-text42">Careers</span>
                <span className="home-text43">Press &amp; Media</span>
              </div>
            </div>
          </div>
        </div>
        <div className="home-bottom">
          <img
            alt="pastedImage"
            src="/smart-defend-high-resolution-logo-black-transparent-200h.png"
            className="home-branding"
          />
          <span className="home-text44">Copyright © Active - 2023</span>
        </div>
      </footer>

    </div>
  )
}

export default Register
