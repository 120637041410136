import React from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import ActiveUsers from '../components/active-users'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Smart Defend</title>
        <meta property="og:title" content="Smart Defend" />
      </Helmet>

      <section className="home-hero">
        <div className="home-menu">
          <div id="mobile-menu" className="home-mobile-navigation">
            <img
              alt="Smart Defend Logo"
              src="/pastedimage-no9b-1500h.png"
              className="home-logo"
            />
            <div className="home-links">
              <span className="Link">Solutions</span>
              <span className="Link">How it works</span>
              <span className="Link">Prices</span>
            </div>
            <div id="close-mobile-menu" className="home-close-mobile-menu">
              <svg viewBox="0 0 804.5714285714286 1024" className="home-icon">
                <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
              </svg>
            </div>
          </div>
          <div className="home-desktop-navigation">
            <nav className="home-centered">
              <div className="home-left">
                <img
                  alt="Smart Defend Logo"
                  src="/smart-defend-high-resolution-logo-black-transparent-200h.png"
                  className="home-logo4"
                />
                <div className="home-links1">
                  <span className="Link">Solutions</span>
                  <span className="Link">How it works</span>
                  <span className="Link">Prices</span>
                </div>
              </div>
              <div className="home-right">
                <span className="home-sign-in Link">
                  <Link to="/register">Sign in</Link>
                </span>
                <div className="home-get-started">
                  <span className="home-text06">
                  <Link to="/register">Get started</Link>
                  </span>
                </div>
                <div id="open-mobile-menu" className="home-burger-menu">
                  <img
                    alt="Open Mobile Menu"
                    src="/pastedimage-yxbd.svg"
                    className="home-mobile-menu-button"
                  />
                </div>
              </div>
            </nav>
          </div>
          <div>
            <div className="home-container2">
              <Script
                html={`<script>
/*
Mobile menu - Code Embed
*/

// Mobile menu
const mobileMenu = document.querySelector("#mobile-menu")

// Buttons
const closeButton = document.querySelector("#close-mobile-menu")
const openButton = document.querySelector("#open-mobile-menu")

// On openButton click, set the mobileMenu position left to -100vw
openButton.addEventListener("click", function() {
    mobileMenu.style.transform = "translateX(0%)"
})

// On closeButton click, set the mobileMenu position to 0vw
closeButton.addEventListener("click", function() {
    mobileMenu.style.transform = "translateX(100%)"
})
</script>`}
              ></Script>
            </div>
          </div>
        </div>
        <header className="home-header">
          <h1 className="home-text07">Cyber Security made Easy</h1>
          <p className="home-text08">
            With just a few clicks, users of all skill levels can quickly and
            easily access industry-leading protection
          </p>
          <div className="home-get-started1">
            <span className="home-text09">
              <Link to="/register">Get started</Link>
            </span>
          </div>
        </header>
        <div className="home-dashboard-preview">
          <div className="home-outline">
            <img
              alt="pastedImage"
              src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/f1e2eda1-7fcc-4dd9-b943-e026a3c84c72/f0dfea35-9037-4dcf-9838-99ed6d4f1145?org_if_sml=1&force_format=original"
              loading="lazy"
              className="home-image"
            />
          </div>
        </div>
      </section>


      <section className="home-features">
        <div className="home-title">
          <span className="home-text10">
            Protect your business with tailored and affordable protection
          </span>
          <span className="home-text11">
            Cyber attacks can be devastating for small and medium-sized
            businesses. Are you ready?
          </span>
        </div>
        <div className="home-cards">
          <div className="home-container3">
            <div className="card">
              <img
                alt="pastedImage"
                src="/pastedimage-fii6m-200h.png"
                className="home-icon2"
              />
              <span className="home-text12">Real-Time Monitoring</span>
              <span className="home-text13">
                Identify your cyber risk and automatically monitor
                vulnerabilities
              </span>
            </div>
            <div className="home-publish card">
              <img
                alt="pastedImage"
                src="/pastedimage-mimg-200h.png"
                className="home-icon3"
              />
              <span className="home-text14">Education &amp; Awareness</span>
              <span className="home-text15">
                Train and educate your employees with our Cyber Awareness tool.
                Run phishing security tests.
              </span>
            </div>
          </div>
          <div className="home-container4">
            <div className="card home-analyze">
              <img
                alt="pastedImage"
                src="/pastedimage-l6p-200h.png"
                className="home-icon4"
              />
              <span className="home-text16">Actionably Insights</span>
              <span className="home-text17">
                <span className="home-text18">
                  Fully automated, for the improvement of your cyber security
                  standards and the identification of &apos;easy fixes’.
                </span>
                <br></br>
                <br></br>
              </span>
            </div>
            <div className="card">
              <img
                alt="pastedImage"
                src="/pastedimage-vyi5-200h.png"
                className="home-icon5"
              />
              <span className="home-text21">360° Cyber Protection</span>
              <span className="home-text22">
                Our domain monitoring service protects you against domain
                typo-squatting, email phishing and website spoofing.
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="home-quote-container">
        <div className="home-quote">
          <span className="home-message">
            “Smart Defend is unlike any other cyber risk product on the market
            today. In a world where cyber risks are threatening, beyond
            technical, costly and evolving so rapidly, Smart Defend offers a
            simple, beautifully designed, easy to understand product that helps
            me understand and mitigate these cyber risks.”
          </span>
          <div className="home-author">
            <img alt="image" src="/tool/emu-200w.png" className="home-avatar" />
            <span className="home-quote1">
              <span className="home-text23">—  Emanuel Hoch</span>
              <span>, Co-Founder &amp; CPO-CTO at Compado</span>
            </span>
          </div>
        </div>
      </section>
      
      <section className="home-statistics">
        <div className="home-container5">
          <ActiveUsers caption="— Active users" statistic="3.5M"></ActiveUsers>
          <ActiveUsers caption="— Brands" statistic="50k"></ActiveUsers>
          <ActiveUsers
            caption="— Accounts Managed"
            statistic="250K"
          ></ActiveUsers>
          <ActiveUsers caption="— Active leads" statistic="30M"></ActiveUsers>
        </div>
      </section>

      <section className="home-banners">
        <div className="home-banner-manage">
          <div className="home-container6">
            <div className="home-left1">
              <span className="sub-title">
                An award-winning, all-in-one solution
              </span>
              <span className="home-text26 title">
                Cybersecurity that protects you. So you can focus on your
                business.
              </span>
              <span className="home-text27">
                <span>
                  {' '}
                  We take control of your cyber security, so you can keep
                  control of your business We do all the work, so you don&apos;t
                  have too ...
                </span>
                <br></br>
              </span>
              <div className="home-get-started2 template-button">
                <span className="home-text30">
                  <Link to="/register">
                    Learn more
                  </Link>
                </span>
              </div>
            </div>
            <div className="home-image-container">
              <img
                alt="pastedImage"
                src="https://images.unsplash.com/photo-1624969862644-791f3dc98927?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE2fHxjeWJlciUyMHxlbnwwfHx8fDE3MDE5ODE5NDZ8MA&amp;ixlib=rb-4.0.3&amp;w=1200"
                className="home-cards-image"
              />
            </div>
          </div>
        </div>
      </section>
  
      <section className="home-action-bar">
        <div className="home-action">
          <div className="home-heading">
            <span className="home-text31 title">
              Turn your security into a business enabler!
            </span>
            <span className="home-text32">
              Every industry has different aims and challenges. That’s why we’ve
              designed  Smart Defend to respond to how you work, and what you
              value.
            </span>
            <div className="home-get-started3 template-button">
              <span className="home-text33">
                <Link to="/register">
                  <span>Free Cyber Health Check</span>
                </Link>
                <br></br>
              </span>
            </div>
          </div>
          <div className="home-images"></div>
        </div>
      </section>
      
      <footer className="home-footer">
        <div className="home-top">
          <div className="home-right1">
            <div className="home-category">
              <span className="home-text39">Company</span>
              <div className="home-links2">
                <span className="home-text40">About</span>
                <span className="home-text41">Team</span>
                <span className="home-text42">Careers</span>
                <span className="home-text43">Press &amp; Media</span>
              </div>
            </div>
          </div>
        </div>
        <div className="home-bottom">
          <img
            alt="pastedImage"
            src="/smart-defend-high-resolution-logo-black-transparent-200h.png"
            className="home-branding"
          />
          <span className="home-text44">Copyright © Active - 2023</span>
        </div>
      </footer>

    </div>
  )
}

export default Home
